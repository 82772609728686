import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // For responsive behavior
  const navigate = useNavigate();
  const token = localStorage.getItem('access_token'); // Check if token exists

  const handleLogout = () => {
    localStorage.removeItem('access_token'); // Remove the access token
    localStorage.removeItem('refresh_token'); // Optionally remove the refresh token
    navigate('/login'); // Redirect to login after logout
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Menu items with links to each page
  const menuItems = [
    { label: 'Home', path: '/' },
    { label: 'Training Plans', path: '/training-plans' },
    { label: 'Race Prep', path: '/race-prep' }, // Example page, add this page if needed
    { label: 'Contact', path: '/contact' }, // Example page, add this page if needed
  ];

  // Drawer for mobile view
  const drawer = (
    <Box sx={{ width: 250 }} onClick={handleDrawerToggle}>
      <List>
        {menuItems.map((item, index) => (
          <ListItem button key={index} component={Link} to={item.path}> {/* Use Link for navigation */}
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: theme.palette.error.main }}>
        <Toolbar>
          {isMobile && (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h5" sx={{ fontWeight: 'bold', color:'#222', flexGrow: 1 }}>
            Baldwin Special OCR
          </Typography>

          {!isMobile && (
            <Box>
              {menuItems.map((item, index) => (
                <Button
                  key={index}
                  color="inherit"
                  component={Link}
                  to={item.path} // Use Link to navigate to the respective path
                >
                  {item.label}
                </Button>
              ))}
            </Box>
          )}
          {/* Show Logout button if user is logged in */}
        {token ? (
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        ) : (
          // Show Login and Register buttons if user is not logged in
          <>
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
            <Button color="inherit" component={Link} to="/register">
              Register
            </Button>
          </>
        )}
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile view */}
      <Drawer anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Navbar;
