import React from 'react';
import { Typography, Grid, Card, CardContent, CardMedia, Button, Box } from '@mui/material';
import RopeClimb from './Images/RopeClimb.png';
import Nutrition from './Images/Nutrition.png';
import Wall from './Images/CrushWall.png';
import Mental from './Images/MentalTough.png';
import MonkeyBars from './Images/MonkeyBars.png';
import Carries from './Images/Carries.png';
import { Link as RouterLink } from 'react-router-dom';

const tips = [
  {
    title: 'Master the Rope Climb',
    description: 'Learn the S-hook and other techniques to conquer the rope climb with ease.',
    image: RopeClimb,  // Replace with actual image URL
    path: '/race-prep/rope-climb',
  },
  {
    title: 'Fuel Your Body Right',
    description: 'Proper nutrition is essential for peak performance on race day.',
    image: Nutrition,  // Replace with actual image URL
    path: '/race-prep/fuel-your-body',
  },
  {
    title: 'Crush the Wall Obstacle',
    description: 'Develop the strength and skills needed to overcome the wall obstacle.',
    image: Wall,  // Replace with actual image URL
    path: '/race-prep/wall-obstacle',
  },
  {
    title: 'Mental Toughness',
    description: 'Prepare mentally for the race with visualization and focus exercises.',
    image: Mental,  // Replace with actual image URL
    path: '/race-prep/mental-toughness'
  },
  {
    title: 'Monkey Bars Mastery',
    description: 'Build grip strength, core stability, and upper body endurance to confidently conquer the monkey bars.',
    image: MonkeyBars,  // Replace with actual image URL
    path: '/race-prep/monkey-bars',
  },
  {
    title: 'Carries and Drags',
    description: 'Strengthen your legs, back, and core with exercises focused on carrying and dragging heavy objects.',
    image: Carries,  // Replace with actual image URL
    path: '/race-prep/carries-drags',
  },
];

const RacePrepTips = () => {
  return (
    <Box sx={{ marginTop: '50px', marginBottom: '50px' }}>
      <Typography variant="h4" style={{ color: '#000', background: 'linear-gradient(to bottom, white, #333)',  marginRight: '20%', marginLeft: '20%', padding: '10px', borderRadius: '10px' }} component="h2" align="center" gutterBottom>
        Race Prep Tips
      </Typography>
      <Grid container spacing={4}>
        {tips.map((tip, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={tip.image}
                alt={tip.title}
              />
              <CardContent>
                <Typography variant="h5" component="h3" gutterBottom>
                  {tip.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {tip.description}
                </Typography>
                <Button component={RouterLink} to={tip.path} variant="contained" color="error">
                  Learn More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RacePrepTips;
