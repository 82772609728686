import React from 'react';
import { Typography, Box } from '@mui/material';

const AdvancedPlan = () => {
  return (
    <Box sx={{ 
      marginTop: '5%',
      marginRight: '15%',
      marginLeft: '15%',
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff',
      borderRadius: '10px',
      background: 'linear-gradient(to bottom, white, #333)', 
    }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Advanced Training Plan
      </Typography>
      <Typography variant="body1" paragraph>
        The 8-week Advanced Training Plan is designed for athletes with a solid base of fitness and experience in obstacle course racing. This plan focuses on pushing your limits with high-intensity workouts, advanced obstacle techniques, and long endurance runs.
      </Typography>
  
      <Typography variant="h5" gutterBottom>
        Key Focus Areas:
      </Typography>
      <ul>
        <li>Advanced strength and power training to prepare for challenging obstacles.</li><br />
        <li>Maximizing endurance with long runs and interval training.</li><br />
        <li>Refining obstacle-specific skills, including carries, climbs, and technical obstacles.</li><br />
      </ul>
  
      <Typography variant="h5" gutterBottom>
        Weekly Breakdown:
      </Typography>
  
      <Typography variant="h6" gutterBottom>
        Week 1-4 Overview:
      </Typography>
      <ul>
        <li>Day 1: 60-minute tempo run + strength training (pull-ups, push-ups, deadlifts, squats).</li><br />
        <li>Day 2: Cross-training (swimming, cycling) + core work (planks, hanging leg raises).</li><br />
        <li>Day 3: 75-minute run + obstacle practice (rope climbs, wall obstacles).</li><br />
        <li>Day 4: Rest or active recovery (yoga, stretching).</li><br />
        <li>Day 5: High-intensity interval training (HIIT) + strength training (burpees, sled pushes, farmer’s carries).</li><br />
        <li>Day 6: Long endurance run (90 minutes) with obstacle simulation (carry heavy objects, monkey bars, etc.).</li><br />
        <li>Day 7: Rest day.</li><br />
      </ul>
  
      <Typography variant="h6" gutterBottom>
        Week 5-8 Overview:
      </Typography>
      <ul>
        <li>Day 1: 75-minute run + strength training (deadlifts, pull-ups, kettlebell swings).</li><br />
        <li>Day 2: Cross-training + core (Russian twists, bicycle crunches).</li><br />
        <li>Day 3: 90-minute run + hill sprints or stair climbs for power.</li><br />
        <li>Day 4: Rest or active recovery (light stretching, walking).</li><br />
        <li>Day 5: Obstacle-specific training (rope climbs, heavy carries, wall obstacles).</li><br />
        <li>Day 6: Long run (2 hours) with obstacle practice (carries, walls, rope climbs, and more).</li><br />
        <li>Day 7: Rest day.</li><br />
      </ul>
  
      <Typography variant="h6" gutterBottom>
        Action Steps:
      </Typography>
      <ul>
        <li>Incorporate HIIT and strength training 3 times per week to build power and endurance.</li><br />
        <li>Focus on long runs and interval training to improve race endurance and speed.</li><br />
        <li>Practice advanced obstacle techniques regularly to refine skills for challenging races.</li><br />
      </ul>
    </Box>
  );
  
};

export default AdvancedPlan;
