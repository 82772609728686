import React from 'react';
import { Typography, Box } from '@mui/material';

const FuelYourBodyRight = () => {
  return (
    <Box sx={{ 
      marginTop: '5%',
      marginRight: '25%',
      marginLeft: '25%',
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: '#fff',
      borderRadius: '10px',
      background: 'linear-gradient(to bottom, white, #333)', 
    }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Fuel Your Body Right
      </Typography>
      <Typography variant="body1" paragraph>
        Proper nutrition plays a pivotal role in your race performance. The right balance of nutrients can fuel your body, enhance stamina, and speed up recovery, so you can push through the course without hitting the wall.
      </Typography>
  
      <Typography variant="h6" gutterBottom>
        Key Learning Points:
      </Typography>
      <ul>
        <li>Pre-Race Nutrition: Fuel your body with the right carbs and proteins in the days leading up to the race.</li><br />
        <li>Hydration: Learn the importance of electrolytes and hydration for optimal performance.</li><br />
        <li>Post-Race Recovery: Discover the best foods for muscle recovery and replenishing energy.</li><br />
      </ul>
  
      <Typography variant="h6" gutterBottom>
        Action Steps:
      </Typography>
      <ul>
        <li>Carb-Loading: Two days before the race, gradually increase your intake of complex carbohydrates to store glycogen in your muscles.</li><br />
        <li>Hydration Plan: Drink plenty of water throughout the week and include electrolyte drinks the day before and on race day to stay hydrated.</li><br />
        <li>Post-Race Recovery: Within 30 minutes after finishing, consume a balanced meal or shake with a 3:1 ratio of carbs to protein for optimal recovery.</li><br />
      </ul>
    </Box>
  );
  
};

export default FuelYourBodyRight;
