import React, { useEffect } from 'react';
import { Card, CardContent, Box } from '@mui/material';

const TikTokEmbed = () => {
  // Ensure TikTok embed script loads correctly
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ maxWidth: 600, padding: 1 }}>
        <CardContent>
          <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@karlbaldwin84/video/7294276761834065194" data-video-id="7294276761834065194" style={{ maxWidth: '605px', minWidth: '325px', vh: '80' }} >
            <section>
              <a target="_blank" rel="noreferrer" title="@karlbaldwin84" href="https://www.tiktok.com/@karlbaldwin84?refer=embed">@karlbaldwin84</a>
              <a title="spartanambassador" target="_blank" rel="noreferrer" href="https://www.tiktok.com/tag/spartanambassador?refer=embed">#spartanambassador</a> 
              <a title="spartan" target="_blank" rel="noreferrer" href="https://www.tiktok.com/tag/spartan?refer=embed">#spartan</a> 
              <a title="running" target="_blank" rel="noreferrer" href="https://www.tiktok.com/tag/running?refer=embed">#running</a> 
              <a title="ocr" target="_blank" rel="noreferrer" href="https://www.tiktok.com/tag/ocr?refer=embed">#ocr</a> 
              <a target="_blank" rel="noreferrer" title="♬ original sound - krazykarl" href="https://www.tiktok.com/music/original-sound-7294276763629243178?refer=embed">♬ original sound - krazykarl</a> 
            </section> 
          </blockquote>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TikTokEmbed;

