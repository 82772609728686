import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import { login } from '../api/auth';
import { useNavigate, Link } from 'react-router-dom'; // Import Link for navigation

const Login = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({ username: '', password: '' });
  const [error, setError] = useState('');

  useEffect(() => {
    // Check if user is already logged in
    const token = localStorage.getItem('access_token');
    if (token) {
      navigate('/'); // Redirect to home if logged in
    }
  }, [navigate]); // Only run once when component is mounted

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(form);
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      navigate('/'); // Redirect to home page after login
    } catch (err) {
      setError(err.response.data.detail || 'Login failed');
    }
  };


  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: 'auto',
        marginTop: '50px',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        background: 'linear-gradient(to bottom, white, #333)',
      }}
    >
      <Typography variant="h5" gutterBottom>
        Login
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username"
          name="username"
          fullWidth
          margin="normal"
          value={form.username}
          onChange={handleChange}
          required
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          fullWidth
          margin="normal"
          value={form.password}
          onChange={handleChange}
          required
        />
        <Button variant="contained" color="error" type="submit" fullWidth sx={{ marginTop: '20px' }}>
          Login
        </Button>
      </form>

      {/* Add link to registration page */}
      <Typography variant="body2" sx={{ marginTop: '20px' }}>
        Don't have an account? <Link sx={{ color: 'red' }} to="/register">Register here</Link>
      </Typography>
    </Box>
  );
};

export default Login;
