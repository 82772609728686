import React from 'react';
import { Typography, Grid, Card, CardContent, Button, Box, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import Advanced from './Images/Advanced.png';
import Intermediate from './Images/Intermediate.png';
import Beginner from './Images/Beginner.png';

// Images for each training plan (replace with actual image URLs or local images)
const plans = [
  {
    title: 'Beginner Plan',
    description: 'Build foundational strength and endurance with this 4-week plan.',
    path: '/training-plans/beginner',
    image: Beginner, // Replace with your image URL or local path
  },
  {
    title: 'Intermediate Plan',
    description: 'Take your skills to the next level with this 6-week plan.',
    path: '/training-plans/intermediate',
    image: Intermediate, // Replace with your image URL or local path
  },
  {
    title: 'Advanced Plan',
    description: 'Master every obstacle and get race-ready with this 8-week plan.',
    path: '/training-plans/advanced',
    image: Advanced, // Replace with your image URL or local path
  },
];

const TrainingPlans = () => {
  return (
    <Box sx={{ marginTop: '100px', marginBottom: '50px', background: 'linear-gradient(to bottom, white, #333)',  padding: '20px', borderRadius: '10px' }}>
      <Typography variant="h3" style={{ color: '#000', margin: '50px' }} component="h2" align="center" gutterBottom>
        Choose Your Training Plan
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              {/* Add CardMedia for image */}
              <CardMedia
                component="img"
                height="140"
                image={plan.image} // Use the image URL from the plan object
                alt={plan.title}
              />
              <CardContent>
                <Typography variant="h5" component="h3" gutterBottom>
                  {plan.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {plan.description}
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ marginTop: '10px' }}
                  component={Link}
                  to={plan.path}
                >
                  View Plan
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TrainingPlans;
