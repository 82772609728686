import React from 'react';
import { Typography, Box } from '@mui/material';

const MonkeyBarMaster = () => {
  return (
    <Box sx={{ 
      marginTop: '5%',
      marginRight: '25%',
      marginLeft: '25%',
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: '#fff',
      borderRadius: '10px',
      background: 'linear-gradient(to bottom, white, #333)', 
    }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Monkey Bars Mastery
      </Typography>
      <Typography variant="body1" paragraph>
        Monkey bars are one of the most notorious obstacles for testing grip strength, coordination, and upper body endurance. With the right training, you'll develop the strength and technique to swing through without faltering.
      </Typography>
  
      <Typography variant="h6" gutterBottom>
        Key Learning Points:
      </Typography>
      <ul>
        <li>Grip Endurance: Build the endurance needed to hang and swing for an extended period.</li><br />
        <li>Core Control: Use your core to stabilize your body and avoid unnecessary energy drain.</li><br />
        <li>Momentum Management: Learn how to swing efficiently, letting momentum do the work rather than relying solely on your arms.</li><br />
      </ul>
  
      <Typography variant="h6" gutterBottom>
        Action Steps:
      </Typography>
      <ul>
        <li>Dead Hangs: Practice dead hangs from the bar, gradually increasing your time as your grip improves.</li><br />
        <li>Core Drills: Add hanging leg raises to your routine to build core control while gripping the bars.</li><br />
        <li>Swing Technique: Practice moving hand-over-hand in a relaxed and controlled motion, letting your momentum help you transition between bars.</li><br />
      </ul>
    </Box>
  );
  
};

export default MonkeyBarMaster;
