import React from 'react';
import { Typography, Box } from '@mui/material';

const CarriesAndDrags = () => {
  return (
    <Box sx={{ 
      marginTop: '5%',
      marginRight: '25%',
      marginLeft: '25%',
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: '#fff',
      borderRadius: '10px',
      background: 'linear-gradient(to bottom, white, #333)', 
    }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Carries & Drags
      </Typography>
      <Typography variant="body1" paragraph>
        Carries and drags are full-body challenges that test your strength, endurance, and determination. These obstacles are key in building functional fitness that translates to real-world strength.
      </Typography>
  
      <Typography variant="h6" gutterBottom>
        Key Learning Points:
      </Typography>
      <ul>
        <li>Leg and Core Strength: Build power in your legs and core to carry heavy loads over long distances.</li><br />
        <li>Grip and Upper Body Stamina: Improve your ability to hold onto and drag heavy objects without losing energy.</li><br />
        <li>Breathing Control: Learn how to control your breathing to maintain endurance during long carries and drags.</li><br />
      </ul>
  
      <Typography variant="h6" gutterBottom>
        Action Steps:
      </Typography>
      <ul>
        <li>Farmer’s Walks: Carry heavy weights in each hand for a set distance to build grip and core strength.</li><br />
        <li>Sled Drags: Practice dragging a weighted sled or object to simulate race obstacles.</li><br />
        <li>Breathing Exercises: Focus on steady, controlled breathing while performing heavy carries to conserve energy.</li><br />
      </ul>
    </Box>
  );
  
  
};

export default CarriesAndDrags;
