import React from 'react';
import { Typography, Box } from '@mui/material';

const MentalToughness = () => {
  return (
    <Box sx={{ 
      marginTop: '5%',
      marginRight: '25%',
      marginLeft: '25%',
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: '#fff',
      borderRadius: '10px',
      background: 'linear-gradient(to bottom, white, #333)', 
    }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Mental Toughness
      </Typography>
      <Typography variant="body1" paragraph>
        Racing isn't just about physical strength—it's about mental grit. Building mental toughness helps you push through fatigue, discomfort, and doubt, ensuring you stay focused and determined through every obstacle.
      </Typography>
  
      <Typography variant="h6" gutterBottom>
        Key Learning Points:
      </Typography>
      <ul>
        <li>Positive Self-Talk: Learn how to shift your mindset when you start to feel tired or defeated.</li><br />
        <li>Visualization: Mentally rehearse the race and each obstacle to prepare yourself for the challenges ahead.</li><br />
        <li>Overcoming Pain Barriers: Understand how to manage discomfort without letting it stop you.</li><br />
      </ul>
  
      <Typography variant="h6" gutterBottom>
        Action Steps:
      </Typography>
      <ul>
        <li>Visualization Practice: Spend 5-10 minutes each day visualizing yourself successfully overcoming each obstacle and finishing strong.</li><br />
        <li>Mindfulness: Incorporate mindfulness techniques to stay present during the race, focusing on one obstacle at a time.</li><br />
        <li>Self-Talk Mantras: Develop mantras like “I am strong” or “Keep moving forward” that you can repeat when things get tough.</li><br />
      </ul>
    </Box>
  );
  
};

export default MentalToughness;
