import React from 'react';
import { Typography, Box } from '@mui/material';

const MasterRopeClimb = () => {
  return (
    <Box sx={{ 
      marginTop: '5%',
      marginRight: '25%',
      marginLeft: '25%',
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: '#fff',
      borderRadius: '10px',
      background: 'linear-gradient(to bottom, white, #333)', 
    }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Master the Rope Climb
      </Typography>
      <Typography variant="body1" paragraph>
        Conquering the rope climb is about more than just brute strength—it requires technique, grip, and upper body endurance. With the right approach, you can save energy and build confidence when faced with this common obstacle.
      </Typography>
  
      <Typography variant="h6" gutterBottom>
        Key Learning Points:
      </Typography>
      <ul>
        <li>S-Hook and J-Hook Techniques: Learn the most efficient foot-locking techniques to reduce strain on your arms.</li><br />
        <li>Grip Strength: Develop grip endurance to hold your weight comfortably for extended periods.</li><br />
        <li>Core Engagement: Engage your core to stabilize your body and control movement as you climb.</li><br />
      </ul>
  
      <Typography variant="h6" gutterBottom>
        Action Steps:
      </Typography>
      <ul>
        <li>Practice the S-Hook: Stand by a rope and practice locking your feet into an S-shape around the rope to get a feel for it.</li><br />
        <li>Grip Workouts: Incorporate dead hangs and towel pull-ups into your routine to build grip endurance.</li><br />
        <li>Core Strengthening: Focus on exercises like planks and hanging leg raises to stabilize your body while climbing.</li><br />
      </ul>
    </Box>
  );
  
};

export default MasterRopeClimb;
