import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar'; // The navigation bar component
import TrainingPlans from './TrainingPlans'; // The training plans overview
import BeginnerPlan from './BeginnerPlan'; // The beginner plan page
import IntermediatePlan from './IntermediatePlan'; // The intermediate plan page
import AdvancedPlan from './AdvancedPlan'; // The advanced plan page
import Layout from './Layout';
import Home from './Home';
import RacePrepTips from './RacePrepTips'; // Example Race Prep page
import Contact from './Contact'; // Example Contact page
import MasterRopeClimb from './MasterRopeClimb';
import FuelYourBodyRight from './FuelYourBodyRight.js';
import CrushTheWall from './CrushTheWall';
import MentalToughness from './MentalToughness';
import MonkeyBarMaster from './MonkeyBarMaster';
import CarriesAndDrags from './CarriesAndDrags.js';
import Login from './Components/Login.js';
import Register from './Components/Register.js';
import ProtectedRoute from './Components/ProtectedRoute'; // Import the ProtectedRoute

const App = () => {
  return (
    <Layout>
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} /> {/* Home page */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* Protected routes */}
        <Route
          path="/training-plans"
          element={
            <ProtectedRoute>
              <TrainingPlans />
            </ProtectedRoute>
          }
        />
        <Route
          path="/race-prep"
          element={
            <ProtectedRoute>
              <RacePrepTips />
            </ProtectedRoute>
          }
        /> {/* Training Plans overview */}
        <Route path="/training-plans/beginner" element={<BeginnerPlan />} /> {/* Beginner Plan page */}
        <Route path="/training-plans/intermediate" element={<IntermediatePlan />} /> {/* Intermediate Plan page */}
        <Route path="/training-plans/advanced" element={<AdvancedPlan />} /> {/* Advanced Plan page */}
        <Route path="/race-prep" element={<RacePrepTips />} /> {/* Example Race Prep page */}
        <Route path="/contact" element={<Contact />} /> {/* Example Contact page */}
        <Route path="/race-prep/rope-climb" element={<MasterRopeClimb />} />
        <Route path="/race-prep/fuel-your-body" element={<FuelYourBodyRight />} />
        <Route path="/race-prep/wall-obstacle" element={<CrushTheWall />} />
        <Route path="/race-prep/mental-toughness" element={<MentalToughness />} />
        <Route path="/race-prep/monkey-bars" element={<MonkeyBarMaster />} />
        <Route path="/race-prep/carries-drags" element={<CarriesAndDrags />} />
      </Routes>
    </Router>
    </Layout>
  );
};

export default App;