import React, { useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, TextField, Button, Alert } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MovieIcon from '@mui/icons-material/Movie'; // Placeholder for TikTok
import TikTokEmbed from './TikTokEmbed'; // Assuming TikTokEmbed is a separate component
import axios from 'axios'; // For API requests

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(false);
    setError(false);

    try {
      // Assuming you have a contact API in your backend
      await axios.post('https://backend.baldwinspecialocr.com/api/contact/', formData);
      setFormSubmitted(true);
      setFormData({ name: '', email: '', message: '' });
    } catch (err) {
      setError(true);
    }
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Grid container spacing={2}>
        {/* Left Side: Contact Form in a Card */}
        <Grid item xs={12} md={6}>
          <Card sx={{ background: 'linear-gradient(to bottom, white, #333)', padding: '20px' }}>
            <CardContent>
              <Typography variant="h4" style={{ color: '#000', marginBottom: '20px' }} component="h2" gutterBottom>
                Contact Us
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  sx={{ marginBottom: '20px' }}
                  InputLabelProps={{ style: { color: '#000' } }} 
                  InputProps={{ style: { color: '#fff' } }} 
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  sx={{ marginBottom: '20px' }}
                  InputLabelProps={{ style: { color: '#000' } }}
                  InputProps={{ style: { color: '#fff' } }}
                />
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                  required
                  sx={{ marginBottom: '20px' }}
                  InputLabelProps={{ style: { color: '#000' } }}
                  InputProps={{ style: { color: '#fff' } }}
                />
                <Button variant="contained" color="error" type="submit">
                  Send Message
                </Button>
              </form>
              {formSubmitted && <Alert severity="success" sx={{ marginTop: '20px' }}>Message sent successfully!</Alert>}
              {error && <Alert severity="error" sx={{ marginTop: '20px' }}>Error sending message. Please try again.</Alert>}
            </CardContent>
          </Card>

          {/* Contact Info Card */}
          <Card sx={{ marginTop: '20px', background: 'linear-gradient(to bottom, white, #333)', padding: '20px' }}>
            <CardContent>
              <Typography variant="h4" style={{ color: '#000', marginBottom: '20px' }} component="h2" gutterBottom>
                Contact Info
              </Typography>

              {/* Email */}
              <Typography variant="body1" style={{ color: '#000', marginBottom: '10px' }}>
                <EmailIcon style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                <a href="mailto:baldwinspecialocr@gmail.com" style={{ color: '#000', marginLeft: '5px' }}>
                  baldwinspecialocr@gmail.com
                </a>
              </Typography>

              {/* TikTok (using MovieIcon as placeholder) */}
              <Typography variant="body1" style={{ color: '#000', marginBottom: '10px' }}>
                <MovieIcon style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                <a href="https://www.tiktok.com/@karlbaldwin84" style={{ color: '#000', marginLeft: '5px' }}>
                  @karlbaldwin84
                </a>
              </Typography>

              {/* Instagram */}
              <Typography variant="body1" style={{ color: '#000', marginBottom: '10px' }}>
                <InstagramIcon style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                <a href="https://www.instagram.com/adventure.athlete.84/?hl=en" style={{ color: '#000', marginLeft: '5px' }}>
                  @adventure.athlete.84
                </a>
              </Typography>

              {/* Facebook */}
              <Typography variant="body1" style={{ color: '#000', marginBottom: '10px' }}>
                <FacebookIcon style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                <a href="https://www.facebook.com/krazykarl.kb/" style={{ color: '#000', marginLeft: '5px' }}>
                  @krazykarl.kb
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Right Side: TikTok Embed */}
        <Grid item xs={12} md={6}>
          <TikTokEmbed />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
