import React from 'react';
import { Typography, Box } from '@mui/material';

const CrushTheWall = () => {
  return (
    <Box sx={{ 
      marginTop: '5%',
      marginRight: '25%',
      marginLeft: '25%',
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: '#fff',
      borderRadius: '10px',
      background: 'linear-gradient(to bottom, white, #333)', 
    }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Crush the Wall Obstacle
      </Typography>
      <Typography variant="body1" paragraph>
        Walls are a classic test of strength, agility, and determination in obstacle courses. The ability to push yourself over a wall combines lower-body power, upper-body strength, and smart technique.
      </Typography>
  
      <Typography variant="h6" gutterBottom>
        Key Learning Points:
      </Typography>
      <ul>
        <li>Foot Placement: Learn where to place your foot to get the most leverage as you climb the wall.</li><br />
        <li>Upper Body Strength: Build your shoulders, biceps, and lats to pull yourself over the top.</li><br />
        <li>Mental Focus: Approach the wall with confidence and commitment to avoid hesitation.</li><br />
      </ul>
  
      <Typography variant="h6" gutterBottom>
        Action Steps:
      </Typography>
      <ul>
        <li>Box Jumps: Incorporate box jumps and step-ups to build explosive power in your legs for jumping up onto the wall.</li><br />
        <li>Pull-Ups: Practice pull-ups to strengthen your back, arms, and shoulders, simulating the motion of pulling yourself over.</li><br />
        <li>Visualization: Before the race, visualize yourself scaling the wall quickly and efficiently to boost your confidence.</li><br />
      </ul>
    </Box>
  );
  
};

export default CrushTheWall;
