import axios from 'axios';

const API_URL = 'https://backend.baldwinspecialocr.com/api';

// Register User
export const register = (userData) => {
  return axios.post(`${API_URL}/register/`, userData);
};

// Login User
export const login = (credentials) => {
  return axios.post(`${API_URL}/login/`, credentials);
};

export const refreshToken = () => {
  const refresh = localStorage.getItem('refresh_token');
  return axios.post(`${API_URL}/token/refresh/`, { refresh });
};
