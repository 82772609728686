import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import LOGO from './Images/LOGO2.png'; // Import your logo image

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if on mobile

  const handleStartTraining = () => {
    navigate('/training-plans'); // Navigating to the correct path
  };

  return (
    <div>
      <Box
        component="img"
        src={LOGO} // Replace with your image URL or local path
        alt="Home Image"
        sx={{
          width: isMobile ? '150px' : '300px', // Smaller image for mobile
          height: 'auto',
          zIndex: 1,
          borderRadius: '10px',
          border: '5px solid red',
          marginTop: isMobile ? '10px' : '20px',
          marginBottom: isMobile ? '10px' : '20px', // Add some space below the image
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto', // Center the image
        }}
      />
      <Box
        sx={{
          marginTop: isMobile ? '10%' : '5%', // Adjust margin for mobile
          marginRight: '10%',
          marginLeft: '10%',
          height: 'auto',
          padding: isMobile ? '20px' : '50px', // Adjust padding for mobile
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: '#fff',
          borderRadius: '10px',
          background: 'linear-gradient(to bottom, white, #333)',
        }}
      >
        <Typography variant={isMobile ? 'h4' : 'h3'} gutterBottom>
          Welcome to Baldwin Special OCR
        </Typography>
        <Typography variant={isMobile ? 'body1' : 'h6'} style={{ marginBottom: '20px' }}>
          Start your journey to mastering obstacle courses with personalized training plans.
        </Typography>
        <Button
          variant="contained"
          color="error"
          size={isMobile ? 'medium' : 'large'} // Smaller button for mobile
          onClick={handleStartTraining}
        >
          Start Training
        </Button>
      </Box>
      <Box
        sx={{
          marginTop: '5%',
          marginRight: '25%',
          marginLeft: '25%',
          marginBottom: '20%',
          height: 'auto',
          padding: isMobile ? '20px' : '50px', // Adjust padding for mobile
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: '#fff',
          borderRadius: '10px',
          background: 'linear-gradient(to bottom, white, #333)',
        }}
      >
        <Typography variant={isMobile ? 'h5' : 'h4'} style={{ marginBottom: '20px' }}>
          Conquer Your Own Race!
        </Typography>
        <Button
          variant="contained"
          color="error"
          size={isMobile ? 'medium' : 'large'}
          onClick={handleStartTraining}
        >
          Race Prep Tips
        </Button>
      </Box>
    </div>
  );
};

export default Home;
