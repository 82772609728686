import React from 'react';
import { Typography, Box } from '@mui/material';

const BeginnerPlan = () => {
  return (
    <Box sx={{ 
      marginTop: '5%',
      marginRight: '15%',
      marginLeft: '15%',
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      backgroundColor: '#fff',
      borderRadius: '10px',
      background: 'linear-gradient(to bottom, white, #333)', 
    }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Beginner Training Plan
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to the 4-week Beginner Training Plan! This program is designed to build foundational strength, endurance, and obstacle-specific skills for those who are new to obstacle course racing or looking to improve their fitness level.
      </Typography>
  
      <Typography variant="h5" gutterBottom>
        Key Focus Areas:
      </Typography>
      <ul>
        <li>Building overall strength through bodyweight exercises.</li><br />
        <li>Improving cardiovascular endurance for longer races.</li><br />
        <li>Practicing specific obstacle techniques to gain confidence on race day.</li><br />
      </ul>
  
      <Typography variant="h5" gutterBottom>
        Weekly Breakdown:
      </Typography>
  
      <Typography variant="h6" gutterBottom>
        Week 1-2 Overview:
      </Typography>
      <ul>
        <li>Day 1: 30-minute easy run + bodyweight exercises (push-ups, squats, lunges).</li><br />
        <li>Day 2: Rest or active recovery (walking, yoga).</li><br />
        <li>Day 3: 45-minute run + core workout (planks, mountain climbers).</li><br />
        <li>Day 4: Strength training (bodyweight or light weights).</li><br />
        <li>Day 5: Rest or light activity (swimming, cycling).</li><br />
        <li>Day 6: Long run (45-60 minutes).</li><br />
        <li>Day 7: Rest day.</li><br />
      </ul>
  
      <Typography variant="h6" gutterBottom>
        Week 3-4 Overview:
      </Typography>
      <ul>
        <li>Day 1: 40-minute run + strength training (push-ups, pull-ups, squats).</li><br />
        <li>Day 2: Rest or active recovery (light stretching, walking).</li><br />
        <li>Day 3: 60-minute run + core workout (planks, Russian twists).</li><br />
        <li>Day 4: Strength training (weighted squats, lunges, deadlifts).</li><br />
        <li>Day 5: Rest or active recovery (cycling, swimming).</li><br />
        <li>Day 6: Long run (60-75 minutes).</li><br />
        <li>Day 7: Rest day.</li><br />
      </ul>
  
      <Typography variant="h6" gutterBottom>
        Action Steps:
      </Typography>
      <ul>
        <li>Gradually increase running distance over the 4 weeks.</li><br />
        <li>Incorporate strength training at least 2-3 times per week for optimal performance.</li><br />
        <li>Focus on maintaining form during exercises to prevent injury and build functional strength.</li><br />
      </ul>
    </Box>
  );
  
};

export default BeginnerPlan;
