import React from 'react';
import { Typography, Box } from '@mui/material';

const IntermediatePlan = () => {
  return (
    <Box sx={{ 
      marginTop: '5%',
      marginRight: '15%',
      marginLeft: '15%',
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff',
      borderRadius: '10px',
      background: 'linear-gradient(to bottom, white, #333)', 
    }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Intermediate Training Plan
      </Typography>
      <Typography variant="body1" paragraph>
        This 6-week Intermediate Training Plan is designed for athletes who have a solid fitness foundation and want to improve their performance and endurance for obstacle course racing. The focus will be on increasing speed, strength, and obstacle-specific skills.
      </Typography>
  
      <Typography variant="h5" gutterBottom>
        Key Focus Areas:
      </Typography>
      <ul>
        <li>Building power and endurance through strength and interval training.</li><br />
        <li>Enhancing obstacle efficiency by incorporating advanced techniques.</li><br />
        <li>Improving cardiovascular stamina for longer, more intense races.</li><br />
      </ul>
  
      <Typography variant="h5" gutterBottom>
        Weekly Breakdown:
      </Typography>
  
      <Typography variant="h6" gutterBottom>
        Week 1-3 Overview:
      </Typography>
      <ul>
        <li>Day 1: 45-minute run + strength training (push-ups, pull-ups, squats, burpees).</li><br />
        <li>Day 2: Cross-training (swimming, cycling) + core work (planks, mountain climbers).</li><br />
        <li>Day 3: 60-minute tempo run + hill sprints for endurance and speed.</li><br />
        <li>Day 4: Rest or active recovery (light stretching, yoga).</li><br />
        <li>Day 5: Strength training (weighted lunges, deadlifts, overhead press).</li><br />
        <li>Day 6: Long run (60-75 minutes) with obstacle simulation (practice monkey bars, rope climbs, etc.).</li><br />
        <li>Day 7: Rest day.</li><br />
      </ul>
  
      <Typography variant="h6" gutterBottom>
        Week 4-6 Overview:
      </Typography>
      <ul>
        <li>Day 1: 60-minute interval run + strength training (weighted squats, pull-ups, burpees).</li><br />
        <li>Day 2: Cross-training + core (Russian twists, hanging leg raises).</li><br />
        <li>Day 3: 75-minute run + hill repeats for power and endurance.</li><br />
        <li>Day 4: Rest or active recovery (light stretching, walking).</li><br />
        <li>Day 5: Obstacle-specific strength training (farmer’s carries, sled drags, rope climbs).</li><br />
        <li>Day 6: Long run (75-90 minutes) with obstacle practice (walls, carries, and drags).</li><br />
        <li>Day 7: Rest day.</li><br />
      </ul>
  
      <Typography variant="h6" gutterBottom>
        Action Steps:
      </Typography>
      <ul>
        <li>Incorporate strength training 3 times per week to build the necessary power for obstacles.</li><br />
        <li>Focus on interval and tempo runs to improve both speed and endurance.</li><br />
        <li>Practice specific obstacle techniques at least once per week to refine skills and build confidence.</li><br />
      </ul>
    </Box>
  );
  
};

export default IntermediatePlan;
